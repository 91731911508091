import React from 'react'
import "./Register.css"
import ad from "../../asset/ad/sks-play-md-4.jpg"
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const nav = useNavigate()


  const validationSchema = yup.object({
    email: yup.string().required("Required Feild"),
    name: yup.string().required("Required Feild"),
    password: yup.string().required("Required Feild"),
  });

    const formik = useFormik({
        initialValues:{
            name: "",
            mobile_number: "",
            gender: "",
            dob: "",
            email : "",
            password : ""
        },
        validationSchema: validationSchema,
        onSubmit: (value)=>{
            axios({
                method: "post",
                url: "http://localhost:5000/api/user/register",
                data : value
            }).then((resp)=>{
                console.log(resp)
                if(resp.data.status === 200){
                  toast.success(resp.data.message)
                  nav('/login')
                }else{
                  toast.error(resp.data.message)
                }
            }).catch((err)=>{
                console.log(err)
                toast.error("Somthing went wrong")
            })
        }
    })


  return (
    <div>
        <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme='dark'
/>
        <div className="register_container">
            <div className="form_box_register">
              <div className="left">
                <img src={ad} alt="" />
              </div>
              <div className="right">
              <h1>Create sks Account</h1>
              <p>Create Your Saal Kather Sajaru Account And Stay With Creativity.</p>


              {formik.errors.name && formik.touched.name ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.name}
                    </div>
                  ) : null}

              <input name="name" value={formik.values.name} onChange={formik.handleChange} id="name" type="text" placeholder='Name' />
              
              {formik.errors.email && formik.touched.email ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
              <input name="email" value={formik.values.email} onChange={formik.handleChange} id="email" type="email" placeholder='Email' />

              <input name="mobile_number" value={formik.values.mobile_number} onChange={formik.handleChange} id="mobile_number" type="text" placeholder='Mobile Number' />

              <input name="dob" value={formik.values.dob} onChange={formik.handleChange} id="dob" type="date" placeholder='Date of birth' />

              <input name="gender" value={formik.values.gender} onChange={formik.handleChange} id="gender" type="text" placeholder='Gender' />

                {formik.errors.password && formik.touched.password ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.password}
                    </div>
                  ) : null}
              <input name="password" value={formik.values.password} onChange={formik.handleChange} id="password" type="text" placeholder='Password' />

              <div>
                <button className='sks-btn-black'  onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            }} >Sign Up</button>
              </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Register
