import React, { useEffect, useState } from "react";
import "./Navbar.css"
import { NavLink, Link } from "react-router-dom";
import logo from "../../asset/logo_main.png"
import Navsm from "./Navsm";
export default function Navbar() {

  const [user,setUser] = useState("")
  const [showUserpanel,setShowUserpanel] = useState(false)

  const userName = localStorage.getItem("userName")
  useEffect(()=>{
    setUser(userName)
  }, [])

  const handleUser = ()=>{
      setShowUserpanel(!showUserpanel)
  }
  const handleLogout= () =>{
    localStorage.removeItem("token")
    localStorage.removeItem("userName")
    window.location.href="/";
  }
  return ( 
    <>
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="#home">
            <img src={logo} alt="" width={150} className="d-inline-block align-text-top"/>
          </a>
          <span className="beta">Beta Version</span>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link active" aria-current="page"  to='/' style={({isActive})=>{return{color: isActive ? 'rgba(47, 203, 110, 1)' : 'white'}}}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link"  to='/distribution' style={({isActive})=>{return{color: isActive ? 'rgba(47, 203, 110, 1)' : 'white'}}}>
                  Distributions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to='/pricing#pricing' style={({isActive})=>{return{color: isActive ? 'rgba(47, 203, 110, 1)' : 'white'}}}>
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/promotion" style={({isActive})=>{return{color: isActive ? 'rgba(47, 203, 110, 1)' : 'white'}}}>
                  Promotion
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about" style={({isActive})=>{return{color: isActive ? 'rgba(47, 203, 110, 1)' : 'white'}}}>
                  About & Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <button className="sks-btn" onClick={()=>{
                  window.open("https://www.youtube.com/channel/UCMvqVvcl8Yl8qNJBv2kaMuA?sub_confirmation=1", "_blank")

                }} target='_'>
                  Subscribe
                </button>
              </li>
              {/* <li className="nav-item">
                
                {
                  user ? (
                    <div onClick={handleUser} className="logged_user text-light"><small>Hey,</small><br /> <div className="username" >{user}</div>

                      <div className="user_panel" style={{display : showUserpanel? "block" : "none"}}>
                        <button onClick={handleLogout} type="buttom">Logout</button>
                        <button type="buttom">Profile</button>
                      </div>

                    </div>
                  ) : (
                    <div style={{marginTop:'10px'}} >
                <Link to="/login" className="sks-btn-sec"
                >Login/
                <small>Sign Up</small></Link>
                </div>
                  )
                }
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <Navsm/>
    </>
  );
}
