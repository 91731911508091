import React from 'react'
import './Login.css'
import { useFormik } from "formik";
import * as yup from "yup";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Longin() {

    const nav = useNavigate();
    
    const validationSchema = yup.object({
    email: yup.string().required("Required Feild"),
    password: yup.string().required("Required Feild"),
  });

    const formik = useFormik({
        initialValues:{
            email : "",
            password : ""
        },
        validationSchema: validationSchema,
        onSubmit: (value)=>{
            axios({
                method: "post",
                url: "http://localhost:5000/api/user/login",
                data : value
            }).then((resp)=>{
                console.log(resp)
                if(resp.data.status === 200){
                    localStorage.setItem("token", resp?.data?.token);
                    localStorage.setItem("userName", resp?.data?.user?.name);

                    window.location.href="/";
                   
                }else{
                  toast.error(resp.data.message)
                }
            }).catch((err)=>{
                console.log(err)
                toast.error("Somthing went wrong")
            })
        }
    })

    const  handleSignUp =()=>{
      nav("/register")
    }
  return (
    <div>
    <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme='dark'
/>
        <div className="login_content">
                <div className="left">
                    <h1>Login To Your Account</h1>
                    <div className="form_box">
                    {formik.errors.email && formik.touched.email ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                        <input value={formik.values.email} onChange={formik.handleChange} name="email" id="email" type="email" placeholder='Email' />
                        {formik.errors.password && formik.touched.password ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.password}
                    </div>
                  ) : null}
                        <input type="password" value={formik.values.password} onChange={formik.handleChange} name="password" id="password" placeholder='Password' />
                        <button className="sks-btn" onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            }}>Login</button>
                    </div>
                </div>
                <div className="right">
                    <h2>New Here ?</h2>
                    <p>Sign up and spred your music worlwide through Saal Kather Sajaru Music Distribution</p>
                    <button onClick={handleSignUp} className="sks-btn-black">Sign Up</button>
                </div>
        </div>
    </div>
  )
}

export default Longin
