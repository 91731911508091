import React from 'react'
import './About.css'
function Teamcard(props) {
  return (
    <>
    <div data-aos="flip-up" className="card">
  <img src={props.url} className="card-img-top" alt="..."/>
  <div className="card-body">
    <h5 className="card-text">{props.name}</h5>
    <p>{props.desc}</p>
  </div>
    </div>
    </>
  )
}

export default Teamcard
